<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="">
      <div class="page-header mb-0">
        <h1 class="w-100">{{ $t("NEW") }} {{ $t("CUSTOMER") }}</h1>
      </div>
      <div class="content mt-0">
        <div class="">
          <form @submit.prevent="addCLI">
            <div class="center">
              <div class="row">
                <div class="col-md-12 col-lg-9 col-sm-12">
                  <b-form-group label="Type*" class="mt-2">
                    <b-form-select
                      v-model="v$.newclient.type.$model"
                      :options="optionsTypeClient"
                      :state="validateState('type')"
                      aria-describedby="type-feedback"
                      @change="v$.$reset()"
                    ></b-form-select>
                    <error-handle
                      :list="v$.newclient.type.$errors"
                      id="type-feedback"
                    ></error-handle>
                    <div v-if="erreurlist.type" class="error-message">
                      <ul v-if="Array.isArray(erreurlist.type)">
                        <span
                          v-for="(e, index) in erreurlist.type"
                          :key="index"
                        >
                          {{ e }}
                        </span>
                      </ul>
                      <span v-else>{{ erreurlist.type }}</span>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div
                v-if="newclient.type == 'type.professionnel'"
                style="width: 100%"
              >
                <h5 class="titresociete">
                  {{ $t("CONTACT_S") }}
                </h5>
                <div class="row mt-2">
                  <div class="col-md-4 col-lg-4 col-sm-12 py-0">
                    <b-form-group :label="$t('SOCIETY') + '*'">
                      <b-form-input
                        v-model="v$.newclient.company.$model"
                        oninput="this.value = this.value.toUpperCase()"
                        :state="validateState('company')"
                        aria-describedby="company-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.company.$errors"
                        id="company-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.company" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.company)">
                          <span
                            v-for="(e, index) in erreurlist.company"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.company }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 py-0">
                    <b-form-group :label="$t('STREET') + '*'" label-for="rue">
                      <b-form-input
                        v-model="v$.newclient.rue.$model"
                        oninput="this.value = this.value.toUpperCase()"
                        :state="validateState('rue')"
                        aria-describedby="rue-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.rue.$errors"
                        id="rue-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.rue" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.rue)">
                          <span
                            v-for="(e, index) in erreurlist.rue"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.rue }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 py-0">
                    <b-form-group :label="$t('ZONE')">
                      <b-form-select
                        v-model="newclient.zone"
                        :options="optionsZone"
                        text-field="text"
                        value-field="value"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- {{ $t("ZONE") }} --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                      <div v-if="erreurlist.zone" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.zone)">
                          <span
                            v-for="(e, index) in erreurlist.zone"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.zone }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-lg-4 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('POSTAL_CODE') + '*'"
                      label-for="cp"
                    >
                      <b-form-input
                        v-model="v$.newclient.cp.$model"
                        :state="validateState('cp')"
                        aria-describedby="cp-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.cp.$errors"
                        id="cp-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.cp" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.cp)">
                          <span
                            v-for="(e, index) in erreurlist.cp"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.cp }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 py-0">
                    <b-form-group :label="$t('CITY') + '*'" label-for="ville">
                      <b-form-input
                        v-model="v$.newclient.ville.$model"
                        :state="validateState('ville')"
                        aria-describedby="ville-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.ville.$errors"
                        id="ville-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.ville" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.ville)">
                          <span
                            v-for="(e, index) in erreurlist.ville"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.ville }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 py-0">
                    <b-form-group :label="$t('COUNTRY') + '*'">
                      <multiselect
                        v-model="newclient.pays"
                        :searchable="true"
                        :close-on-select="true"
                        :multiple="false"
                        :options="getAllcountries"
                        label="name"
                        track-by="id"
                      >
                        <template slot="noResult">
                          {{ $t("NO_DATA_FOUND") }}
                        </template>
                      </multiselect>
                      <div v-if="erreurlist.pays" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.pays)">
                          <span
                            v-for="(e, index) in erreurlist.pays"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.pays }}</span>
                      </div>
                      <div
                        v-if="v$.newclient.pays.$error"
                        class="error-message"
                      >
                        {{ $t("REQUIRED_FIELD") }}
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 py-0">
                    <b-form-group :label="$t('COMPANY_PHONE') + '*'">
                      <template>
                        <phone @phoneNumber="formatNumberE($event)"></phone>
                      </template>
                      <div v-if="erreurlist.company_tel" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.company_tel)">
                          <span
                            v-for="(e, index) in erreurlist.company_tel"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.company_tel }}</span>
                      </div>
                      <div
                        v-if="v$.newclient.company_tel.$error"
                        class="error-message"
                      >
                        {{ $t("REQUIRED_FIELD") }}
                      </div>
                      <!-- <div
                v-if="newclient.company_tel && !phoneValid"
                class="error-message"
              >
                {{ $t("ERROR_PHONE") }}
              </div> -->
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('COMPANY_EMAIL') + '*'"
                      label-for="email"
                    >
                      <b-form-input
                        v-model="v$.newclient.company_email.$model"
                        required
                        type="email"
                        :state="validateState('company_email')"
                        aria-describedby="company_email-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.company_email.$errors"
                        id="company_email-feedback"
                      ></error-handle>
                      <div
                        v-if="erreurlist.company_email"
                        class="error-message"
                      >
                        <ul v-if="Array.isArray(erreurlist.company_email)">
                          <span
                            v-for="(e, index) in erreurlist.company_email"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.company_email }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <h5 class="titresociete">{{ $t("COMPANY_INTER") }}</h5>
                <div class="row mt-2">
                  <div class="col-md-4 col-lg-4 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('SURNAME') + '*'"
                      label-for="lastname"
                    >
                      <b-form-input
                        v-model="v$.newclient.prenom.$model"
                        oninput="this.value = this.value.toUpperCase()"
                        :state="validateState('prenom')"
                        aria-describedby="prenom-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.prenom.$errors"
                        id="prenom-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.prenom" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.prenom)">
                          <span
                            v-for="(e, index) in erreurlist.prenom"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.prenom }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 py-0">
                    <b-form-group :label="$t('NAME') + '*'">
                      <b-form-input
                        v-model="v$.newclient.nom.$model"
                        oninput="this.value = this.value.toUpperCase()"
                        :state="validateState('nom')"
                        aria-describedby="nom-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.nom.$errors"
                        id="nom-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.nom" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.nom)">
                          <span
                            v-for="(e, index) in erreurlist.nom"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.nom }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-4 col-lg-4 col-sm-12 py-0">
                    <b-form-group :label="$t('Poste') + '*'">
                      <b-form-input
                        v-model="v$.newclient.poste.$model"
                        oninput="this.value = this.value.toUpperCase()"
                        :state="validateState('poste')"
                        aria-describedby="poste-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.poste.$errors"
                        id="poste-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.poste" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.poste)">
                          <span
                            v-for="(e, index) in erreurlist.poste"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.poste }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('PHONE') + '*'"
                      label-for="telephone"
                    >
                      <template>
                        <phone @phoneNumber="formatNumber($event)"></phone>
                      </template>
                      <div v-if="erreurlist.tel" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.tel)">
                          <span
                            v-for="(e, index) in erreurlist.tel"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.tel }}</span>
                      </div>
                      <div v-if="v$.newclient.tel.$error" class="error-message">
                        {{ $t("REQUIRED_FIELD") }}
                      </div>
                      <div
                        v-if="newclient.tel && resultValid == false"
                        class="error-message"
                      >
                        {{ $t("ERROR_PHONE") }}
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 py-0">
                    <b-form-group :label="$t('EMAIL') + '*'" label-for="email">
                      <b-form-input
                        v-model="v$.newclient.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-feedback"
                        type="email"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.email.$errors"
                        id="email-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.email" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.email)">
                          <span
                            v-for="(e, index) in erreurlist.email"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.email }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <h5 class="titresociete">{{ $t("COMPANY_BANK") }}</h5>
                <div class="row mt-2">
                  <div class="col-md-6 col-lg-6 col-sm-12 py-0">
                    <b-form-group :label="$t('ACCOUNT_OWNER')">
                      <b-form-input
                        v-model="v$.newclient.titulaire_compte.$model"
                        :state="validateState('titulaire_compte')"
                        aria-describedby="titulaire_compte-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.titulaire_compte.$errors"
                        id="titulaire_compte-feedback"
                      ></error-handle>
                      <div
                        v-if="erreurlist.titulaire_compte"
                        class="error-message"
                      >
                        <ul v-if="Array.isArray(erreurlist.titulaire_compte)">
                          <span
                            v-for="(e, index) in erreurlist.titulaire_compte"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.titulaire_compte }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 py-0">
                    <b-form-group label="IBAN" label-for="IBAN">
                      <b-form-input
                        v-model="v$.newclient.IBAN.$model"
                        :state="validateState('IBAN')"
                        aria-describedby="IBAN-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.IBAN.$errors"
                        id="IBAN-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.IBAN" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.IBAN)">
                          <span
                            v-for="(e, index) in erreurlist.IBAN"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.IBAN }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <b-form-group
                      :label="$t('forme_juridique')"
                      label-for="forme_juridique"
                    >
                      <b-form-input
                        v-model="v$.newclient.forme_juridique.$model"
                        :state="validateState('forme_juridique')"
                        aria-describedby="forme_juridique-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.forme_juridique.$errors"
                        id="forme_juridique-feedback"
                      ></error-handle>
                      <div
                        v-if="erreurlist.forme_juridique"
                        class="error-message"
                      >
                        <ul v-if="Array.isArray(erreurlist.forme_juridique)">
                          <span
                            v-for="(e, index) in erreurlist.forme_juridique"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.forme_juridique }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <b-form-group :label="$t('ape')" label-for="ape">
                      <b-form-input
                        v-model="v$.newclient.ape.$model"
                        :state="validateState('ape')"
                        aria-describedby="ape-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.ape.$errors"
                        id="ape-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.ape" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.ape)">
                          <span
                            v-for="(e, index) in erreurlist.ape"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.ape }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12">
                    <b-form-group :label="$t('naf')" label-for="naf">
                      <b-form-input
                        v-model="v$.newclient.naf.$model"
                        :state="validateState('naf')"
                        aria-describedby="naf-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.naf.$errors"
                        id="ape-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.naf" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.naf)">
                          <span
                            v-for="(e, index) in erreurlist.naf"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.naf }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 py-0">
                    <b-form-group label="SWIFT_BIC" label-for="SWIFT_BIC">
                      <b-form-input
                        v-model="v$.newclient.SWIFT_BIC.$model"
                        :state="validateState('SWIFT_BIC')"
                        aria-describedby="SWIFT_BIC-feedback"
                        oninput="this.value = this.value.toUpperCase()"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.SWIFT_BIC.$errors"
                        id="SWIFT_BIC-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.SWIFT_BIC" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.SWIFT_BIC)">
                          <span
                            v-for="(e, index) in erreurlist.SWIFT_BIC"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.SWIFT_BIC }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-lg-6 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('revenu_impo')"
                      label-for="revenu_avis_imposition"
                    >
                      <b-form-input
                        v-model="v$.newclient.revenu_avis_imposition"
                        aria-describedby="revenu_avis_imposition-feedback"
                        type="number"
                        min="0"
                        oninput="this.value = this.value.toUpperCase()"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.revenu_avis_imposition.$errors"
                        id="revenu_avis_imposition-feedback"
                      ></error-handle>
                      <div
                        v-if="erreurlist.revenu_avis_imposition"
                        class="error-message"
                      >
                        <ul
                          v-if="
                            Array.isArray(erreurlist.revenu_avis_imposition)
                          "
                        >
                          <span
                            v-for="(
                              e, index
                            ) in erreurlist.revenu_avis_imposition"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{
                          erreurlist.revenu_avis_imposition
                        }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <hr />

                <b-form-group
                  :label="$t('COMMENTAIRE')"
                  label-for="commentaire"
                >
                  <b-form-textarea
                    v-model="newclient.commentaire"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                  <div v-if="erreurlist.commentaire" class="error-message">
                    <ul v-if="Array.isArray(erreurlist.commentaire)">
                      <span
                        v-for="(e, index) in erreurlist.commentaire"
                        :key="index"
                      >
                        {{ e }}
                      </span>
                    </ul>
                    <span v-else>{{ erreurlist.commentaire }}</span>
                  </div>
                </b-form-group>
              </div>

              <div
                v-if="newclient.type == 'type.particulier'"
                style="width: 100%"
              >
                <div class="row">
                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('SURNAME') + '*'"
                      label-for="lastname"
                    >
                      <b-form-input
                        v-model="v$.newclient.prenom.$model"
                        oninput="this.value = this.value.toUpperCase()"
                        :state="validateState('prenom')"
                        aria-describedby="prenom-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.prenom.$errors"
                        id="prenom-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.prenom" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.prenom)">
                          <span
                            v-for="(e, index) in erreurlist.prenom"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.prenom }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group :label="$t('NAME') + '*'">
                      <b-form-input
                        v-model="v$.newclient.nom.$model"
                        oninput="this.value = this.value.toUpperCase()"
                        :state="validateState('nom')"
                        aria-describedby="nom-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.nom.$errors"
                        id="nom-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.nom" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.nom)">
                          <span
                            v-for="(e, index) in erreurlist.nom"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.nom }}</span>
                      </div>
                    </b-form-group>
                  </div>

                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group :label="$t('EMAIL') + '*'" label-for="email">
                      <b-form-input
                        v-model="v$.newclient.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-feedback"
                        type="email"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.email.$errors"
                        id="email-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.email" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.email)">
                          <span
                            v-for="(e, index) in erreurlist.email"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.email }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group :label="$t('STREET') + '*'" label-for="rue">
                      <b-form-input
                        v-model="v$.newclient.rue.$model"
                        :state="validateState('rue')"
                        aria-describedby="rue-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.rue.$errors"
                        id="rue-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.rue" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.rue)">
                          <span
                            v-for="(e, index) in erreurlist.rue"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.rue }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('POSTAL_CODE') + '*'"
                      label-for="cp"
                    >
                      <b-form-input
                        v-model="v$.newclient.cp.$model"
                        :state="validateState('cp')"
                        aria-describedby="cp-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.cp.$errors"
                        id="cp-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.cp" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.cp)">
                          <span
                            v-for="(e, index) in erreurlist.cp"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.cp }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group :label="$t('CITY') + '*'" label-for="ville">
                      <b-form-input
                        v-model="v$.newclient.ville.$model"
                        :state="validateState('ville')"
                        aria-describedby="ville-feedback"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.ville.$errors"
                        id="ville-feedback"
                      ></error-handle>
                      <div v-if="erreurlist.ville" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.ville)">
                          <span
                            v-for="(e, index) in erreurlist.ville"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.ville }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12 py-0">
                    <b-form-group :label="$t('COUNTRY') + '*'">
                      <multiselect
                        v-model="newclient.pays"
                        :searchable="true"
                        :close-on-select="true"
                        :multiple="false"
                        :options="getAllcountries"
                        label="name"
                        track-by="id"
                      >
                        <template slot="noResult">
                          {{ $t("NO_DATA_FOUND") }}
                        </template>
                      </multiselect>
                      <div v-if="erreurlist.pays" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.pays)">
                          <span
                            v-for="(e, index) in erreurlist.pays"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.pays }}</span>
                      </div>
                      <div
                        v-if="v$.newclient.pays.$error"
                        class="error-message"
                      >
                        {{ $t("REQUIRED_FIELD") }}
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('PHONE') + '*'"
                      label-for="telephone"
                    >
                      <template>
                        <phone @phoneNumber="formatNumber($event)"></phone>
                      </template>
                      <div v-if="erreurlist.tel" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.tel)">
                          <span
                            v-for="(e, index) in erreurlist.tel"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.tel }}</span>
                      </div>
                      <div v-if="v$.newclient.tel.$error" class="error-message">
                        {{ $t("REQUIRED_FIELD") }}
                      </div>
                      <div
                        v-if="
                          newclient.tel.phone_number && resultValid == false
                        "
                        class="error-message"
                      >
                        {{ $t("ERROR_PHONE") }}
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group :label="$t('ZONE')">
                      <b-form-select
                        v-model="newclient.zone"
                        :options="optionsZone"
                        text-field="text"
                        value-field="value"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- {{ $t("ZONE") }} --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                      <div v-if="erreurlist.zone" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.zone)">
                          <span
                            v-for="(e, index) in erreurlist.zone"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.zone }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group :label="$t('PRECARITE')">
                      <b-form-select
                        v-model="newclient.precarite"
                        :options="optionsprecarite"
                        text-field="text"
                        value-field="value"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- {{ $t("PRECARITE") }} --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                      <div v-if="erreurlist.precarite" class="error-message">
                        <ul v-if="Array.isArray(erreurlist.precarite)">
                          <span
                            v-for="(e, index) in erreurlist.precarite"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{ erreurlist.precarite }}</span>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-md-12 col-sm-12 py-0">
                    <b-form-group
                      :label="$t('revenu_impo')"
                      label-for="revenu_avis_imposition"
                    >
                      <b-form-input
                        v-model="v$.newclient.revenu_avis_imposition.$model"
                        aria-describedby="revenu_avis_imposition-feedback"
                        type="number"
                        min="0"
                        oninput="this.value = this.value.toUpperCase()"
                      ></b-form-input>
                      <error-handle
                        :list="v$.newclient.revenu_avis_imposition.$errors"
                        id="revenu_avis_imposition-feedback"
                      ></error-handle>
                      <div
                        v-if="erreurlist.revenu_avis_imposition"
                        class="error-message"
                      >
                        <ul
                          v-if="
                            Array.isArray(erreurlist.revenu_avis_imposition)
                          "
                        >
                          <span
                            v-for="(
                              e, index
                            ) in erreurlist.revenu_avis_imposition"
                            :key="index"
                          >
                            {{ e }}
                          </span>
                        </ul>
                        <span v-else>{{
                          erreurlist.revenu_avis_imposition
                        }}</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <!-- <b-form-group :label="$t('TYPE_CHAUFFAGE')">
              <b-form-select
                v-model="newclient.type_chauffage"
                :options="optionstypechauffage"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("TYPE_CHAUFFAGE") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
              <div v-if="erreurlist.type_chauffage" class="error-message">
                <ul v-if="Array.isArray(erreurlist.type_chauffage)">
                  <span
                    v-for="(e, index) in erreurlist.type_chauffage"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.type_chauffage }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('TYPE_LOGEMENT')">
              <b-form-select
                v-model="newclient.type_logement"
                :options="optionstypelogement"
                text-field="text"
                value-field="value"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- {{ $t("TYPE_LOGEMENT") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
              <div v-if="erreurlist.type_logement" class="error-message">
                <ul v-if="Array.isArray(erreurlist.type_logement)">
                  <span
                    v-for="(e, index) in erreurlist.type_logement"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.type_logement }}</span>
              </div>
            </b-form-group>  -->
              </div>
              <h5>{{ $t("Menage") }}</h5>

              <MenagesStep
                :menageParentData="menageData"
                @update-menage="handleMenageUpdate"
              />
              <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
                <multiselect
                  v-model="newclient.entreprise"
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllentreprises"
                  label="lib"
                  track-by="id"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
                <div
                  v-if="v$.newclient.entreprise.$error"
                  class="error-message"
                >
                  {{ $t("REQUIRED_FIELD") }}
                </div>
                <div v-if="erreurlist.entreprise" class="error-message">
                  <ul v-if="Array.isArray(erreurlist.entreprise)">
                    <span
                      v-for="(e, index) in erreurlist.entreprise"
                      :key="index"
                    >
                      {{ e }}
                    </span>
                  </ul>
                  <span v-else>{{ erreurlist.entreprise }}</span>
                </div>
              </b-form-group>
            </div>
          </form>

          <div>
            <b-alert variant="warning" show v-if="errorS" class="messageError">
              {{ errorS }}
            </b-alert>
            <div class="double d-flex justify-content-end">
              <b-button variant="danger" @click="resetModal()" class="mr-2">
                <div class="block-spinner">
                  {{ $t("CANCEL") }}
                </div>
              </b-button>
              <b-button variant="success" @click="addCLI">
                <div class="block-spinner">
                  {{ $t("SAVE") }}
                  <div v-if="getclientLoading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Snackbar ref="snackbar" class="mt-5" />
  </div>
</template>

<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { domains } from "@/environment";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import Snackbar from "@/components/ui/errorSnackbar.vue";

import {
  required,
  email,
  maxLength,
  minLength,
  requiredIf,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";
import phone from "../ui/phone.vue";
import MenagesStep from "../menages/MenagesStep.vue";
export default {
  components: {
    errorHandle,
    phone,
    MenagesStep,
    Snackbar,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },

  data() {
    return {
      menageData: {},
      newclient: {
        type: "type.particulier",
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        pays: null,
        entreprise: null,
        company: null,
        company_email: null,
        company_tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        forme_juridique: null,
        ape: null,
        naf: null,
        SWIFT_BIC: null,
        revenu_avis_imposition: null,
        IBAN: null,
        titulaire_compte: null,
        poste: null,
        commentaire: null,
        zone: null,
        precarite: null,
        //   type_chauffage: null,
        //   type_logement: null,
      },
      erreurlist: {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        forme_juridique: null,
        ape: null,
        naf: null,
        revenu_avis_imposition: null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_tel: null,
        zone: null,
        precarite: null,
        //   type_chauffage: null,
        //   type_logement: null,
      },
      galleryUrl: domains.gallery,
      optionsTypeClient: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      optionsZone: [
        { value: "h1", text: "H1" },
        { value: "h2", text: "H2" },
        { value: "h3", text: "H3" },
      ],
      optionsprecarite: [
        { value: "Modeste", text: this.$t("Modeste") },
        { value: "Intermédiaires", text: this.$t("Intermédiaires") },
        { value: "tres_modeste", text: this.$t("Tmodeste") },
        { value: "supérieurs", text: this.$t("Supérieurs") },
      ],
      optionstypechauffage: [
        { value: "combustible_bois", text: this.$t("Combustible/Bois") },
        { value: "electrique", text: this.$t("Electrique") },
        { value: "gaz", text: this.$t("Gaz") },
        { value: "fuel", text: this.$t("Fuel") },
        { value: "bois", text: this.$t("Bois") },
        { value: "pac", text: "PAC" },
      ],
      optionstypelogement: [
        { value: "maison_individuelle", text: this.$t("Maison individuelle") },
        { value: "appartement", text: this.$t("Appartement") },
      ],
      errorS: null,
      box: "",
      resultValid: null,
      phoneValid: null,
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
    };
  },
  validations() {
    return {
      newclient: {
        type: { required },
        nom: { required, maxLength: maxLength(100) },
        prenom: { required, maxLength: maxLength(100) },
        email: { required, email, maxLength: maxLength(50) },
        rue: { required, maxLength: maxLength(100) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: { required, maxLength: maxLength(100) },
        tel: {
          phone_number: { required },
        },
        pays: { required },
        entreprise: { requiredIf: requiredIf(this.isSuperAdmin) },
        company: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(100),
        },
        company_email: {
          email,
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(50),
        },
        company_tel: {
          phone_number: { requiredIf: requiredIf(this.isProfessionel) },
        },
        poste: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(40),
        },
        revenu_avis_imposition: {
          required,
        },
        forme_juridique: {
          requiredIf: requiredIf(this.isProfessionel),
        },
        naf: {
          requiredIf: requiredIf(this.isProfessionel),
        },
        ape: {
          requiredIf: requiredIf(this.isProfessionel),
        },
        SWIFT_BIC: {
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        IBAN: { maxLength: maxLength(34), alphaNum },
        titulaire_compte: { maxLength: maxLength(40) },
      },
    };
  },
  methods: {
    ...mapActions(["store_clients", "all_countries", "storeMenage"]),
    handleMenageUpdate({ field, value }) {
      if (!this.menageData) {
        this.menageData = {};
      }
      this.menageData[field] = value;
    },

    validateState(name) {
      const { $dirty, $error } = this.v$.newclient[name];
      return $dirty ? !$error : null;
    },

    formatNumber(event) {
      this.newclient.tel = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.resultValid = event.isValid;
    },

    formatNumberE(event) {
      this.newclient.company_tel = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.phoneValid = event.isValid;
    },

    async addCLI() {
      if (
        !this.menageData.personnes ||
        !this.menageData.commune ||
        !this.menageData.revenu
      ) {
        this.$refs.snackbar.showSnackbar("Tous_obligatoires");

        this.isLoading = false;
        return;
      }
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.erreurlist = {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        revenu_avis_imposition: null,
        SWIFT_BIC: null,
        forme_juridique: null,
        ape: null,
        naf: null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_tel: null,
        zone: null,
        precarite: null,
        //   type_chauffage: null,
        //   type_logement: null,
      };
      this.errorS = null;

      if (this.resultValid == false) return;

      if (this.newclient.company_tel && this.phoneValid == false) return;

      const data = {
        type: this.newclient.type,
        nom: this.newclient.nom,
        prenom: this.newclient.prenom,
        email: this.newclient.email,
        rue: this.newclient.rue,
        cp: this.newclient.cp,
        ville: this.newclient.ville,
        pays: this.newclient.pays.iso_code,
        revenu_avis_imposition: this.newclient.revenu_avis_imposition,
      };

      // Format numero tel
      var num = this.newclient.tel.phone_number.indexOf(" ");

      data.phone_number = this.newclient.tel.phone_number
        .slice(num + 1)
        .split(" ")
        .join("");
      data.tel = data.phone_number;
      data.phone_iso_code = this.newclient.tel.phone_iso_code;

      // Format numero tel company
      if (this.newclient.type == "type.professionnel") {
        var numE = this.newclient.company_tel.phone_number.indexOf(" ");
        data.company_tel = this.newclient.company_tel.phone_number
          .slice(numE + 1)
          .split(" ")
          .join("");

        data.company_phone_number = data.company_tel;
        data.company_phone_iso_code = this.newclient.company_tel.phone_iso_code;
      }

      if (this.isCommercialBatigo) {
        data.entreprise_id = this.$route.query.entreprise;
      }
      if (this.isSuperAdmin) {
        if (this.newclient.entreprise)
          data.entreprise_id = this.newclient.entreprise.id;
        else data.entreprise_id = this.getOnlineUser.entreprise.id;
      }
      if (this.newclient.zone) {
        data.zone = this.newclient.zone;
      }
      if (this.newclient.precarite) {
        data.precarite = this.newclient.precarite;
      }
      // if (this.newclient.type_chauffage) {
      //   data.type_chauffage = this.newclient.type_chauffage;
      // }
      // if (this.newclient.type_logement) {
      //   data.type_logement = this.newclient.type_logement;
      // }
      if (this.newclient.company) {
        data.company = this.newclient.company;
      }
      if (this.newclient.titulaire_compte) {
        data.titulaire_compte = this.newclient.titulaire_compte;
      }
      if (this.newclient.poste) {
        data.poste = this.newclient.poste;
      }
      if (this.newclient.commentaire) {
        data.commentaire = this.newclient.commentaire;
      }
      // if (this.newclient.revenu_avis_imposition) {
      //   data.revenu_avis_imposition = this.newclient.revenu_avis_imposition;
      // }
      if (this.newclient.SWIFT_BIC) {
        data.SWIFT_BIC = this.newclient.SWIFT_BIC;
      }

      if (this.newclient.forme_juridique) {
        data.forme_juridique = this.newclient.forme_juridique;
      }
      if (this.newclient.ape) {
        data.ape = this.newclient.ape;
      }
      if (this.newclient.naf) {
        data.naf = this.newclient.naf;
      }

      if (this.newclient.IBAN) {
        data.IBAN = this.newclient.IBAN;
      }
      if (this.newclient.company_email) {
        data.company_email = this.newclient.company_email;
      }
      if (this.newclient.poste) {
        data.poste = this.newclient.poste;
      }
      try {
        const clientResult = await this.store_clients(data);
        if (
          clientResult.status_code === 422 ||
          clientResult.status_code === 409
        ) {
          this.$refs.snackbar.showSnackbar("e-mail existe déjà");

          this.isLoading = false;
          return;
        }
        const menage = {
          client_id: clientResult.id,
          revenu: this.menageData.revenu,
          personnes: this.menageData.personnes,
          commune: this.menageData.commune,
        };
        await this.storeMenage(menage);

        this.resetModal();
        this.$router.push("/client");
        this.errorS = null;
      } catch (err) {
        if (this.isObject(err)) {
          for (const [key, value] of Object.entries(err)) {
            if (!this.erreurlist[key]) {
              this.erreurlist[key] = value;
            }
          }
        } else {
          this.errorS = err;
        }
      }

      //       }
      //        else {
      //         this.errorS = err;

      //       }
      //     });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    resetModal() {
      this.errorS = null;
      // this.$refs["clientStoreModal"].hide();
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.erreurlist = {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        forme_juridique: null,
        ape: null,
        naf: null,
        revenu_avis_imposition: null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        company: null,
        company_email: null,
        company_tel: null,
        zone: null,
        precarite: null,
        //   type_chauffage: null,
        //   type_logement: null,
      };
      this.newclient = {
        type: "type.particulier",
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        company: null,
        company_email: null,
        company_tel: {
          phone_number: null,
          phone_iso_code: null,
        },
        pays: null,
        SWIFT_BIC: null,
        forme_juridique: null,
        ape: null,
        naf: null,
        revenu_avis_imposition: null,
        IBAN: null,
        titulaire_compte: null,
        poste: null,
        commentaire: null,
        zone: null,
        precarite: null,
        //   type_chauffage: null,
        //   type_logement: null,
      };
      this.$router.push("/client");
    },

    close() {
      this.$router.push("/client");
      // if (this.$refs["clientStoreModal"].isShow) {
      //   if (bv.trigger == "backdrop") {
      //     bv.preventDefault();
      //     this.showMsgBox();
      //   }
      // }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
  },

  mounted() {
    this.all_countries({ page: this.page, perPage: 100000 });
  },

  computed: {
    ...mapGetters([
      "getTabs",
      "getSelectedLang",
      "getPays",
      "getclientLoading",
      "getOnlineUser",
      "getAllentreprises",
      "getdictionnaire",
      "getAllcountries",
    ]),

    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isProfessionel() {
      return this.newclient.type == "type.professionnel";
    },
  },
};
</script>

<style lang="scss" scoped>
.actionModel {
  position: relative;
  padding: 52px 0 0;

  .messageError {
    position: absolute;
    top: 5px;
    bottom: 44px;
    color: #e4261b;
    margin: 0;
    font-size: 15px;
    background-color: #fff;
    border-color: #fff;
  }
}
.titresociete {
  color: #334081;
  width: 100%;
  margin-top: 4px;
}
.inner-container .content {
  margin: 10px 15px;
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
  border-radius: 5px;
  padding: 14px;
  min-height: 88vh;
  margin-top: 55px;
}
</style>
